import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "./App.css";

import LoginPage from "./LoginPage";
import Homepage from "./Homepage";
import UserPage from "./UserPage";
import AdminPage from "./AdminPage";
import PageNotFound from "./PageNotFound";


const firebaseConfig = {
  apiKey: "AIzaSyCoQ_gjcBsa2RaD0ygZs1SF0hZaItKuZCU",
  authDomain: "labelslab-internal.firebaseapp.com",
  projectId: "labelslab-internal",
  storageBucket: "labelslab-internal.appspot.com",
  messagingSenderId: "451548227886",
  appId: "1:451548227886:web:36fdf56d87b1987911b2bf",
  measurementId: "G-ZRJGHQVFGZ"
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

const App = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        setUser(currentUser);

        const userSnapshot = await firestore
          .collection("admins")
          .doc(currentUser.uid)
          .get();
        setIsAdmin(userSnapshot.exists && userSnapshot.data().isAdmin);
      } else {
        setUser(null);
        setIsAdmin(false);
      }
    };

    const unsubscribe = firebase.auth().onAuthStateChanged(checkUser);
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    firebase.auth().signOut();
  };

  return (
    <Router>
      <nav className="bg-white border-gray-200 dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to="/" className="flex items-center">
            {/* <img src="https://flowbite.com/docs/images/logo.svg" className="h-8 mr-3" alt="Flowbite Logo" /> */}
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Internal Communications
            </span>
          </Link>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link
                  to="/"
                  className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>

              {isAdmin && (
                <li>
                  <Link
                    to="/admin"
                    className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                    aria-current="page"
                  >
                    Admin
                  </Link>
                </li>
              )}

              {user ? (
                <>
                  <li>
                    <Link
                      to="/user"
                      className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                      aria-current="page"
                    >
                      User
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={handleLogout}
                      className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                      aria-current="page"
                    >
                      Logout
                    </button>
                  </li>
                </>
              ) : (
                <li>
                  <Link
                    to="/login"
                    className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                    aria-current="page"
                  >
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {user ? (
          <>
            <Route path="/" element={<Homepage />} />
            <Route path="/user" element={<UserPage />} />
            <Route path="*" element={<PageNotFound />} />
          </>
        ) : (
          <Route
            path="/"
            element={
              <section className="bg-white dark:bg-gray-900 ">
                <div className="py-24 sm:py-32 min-h-screen px-6 py-12">
                  <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <p className="text-sm font-medium text-blue-500 dark:text-blue-400">
                      Please Login to View the content
                    </p>
                    <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
                      You are not Authenticated to view the contents
                    </h1>

                    <div className="flex items-center mt-6 gap-x-3">
                      <Link
                        to="/login"
                        className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                      >
                        Login Here
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            }
          />
        )}
        {isAdmin && <Route path="/admin" element={<AdminPage />} />}
      </Routes>
    </Router>
  );
};

export default App;
