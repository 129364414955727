import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const UserPage = () => {
  const [username, setUsername] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchUsername = async () => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const usernamesRef = firebase
          .firestore()
          .collection("usernames")
          .doc(currentUser.uid);
        const doc = await usernamesRef.get();
        if (doc.exists) {
          setUsername(doc.data().name);
        }
      }
    };

    fetchUsername();
  }, []);

  const handleEdit = () => {
    setNewUsername(username);
    setEditMode(true);
  };

  const handleSave = async () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const usernamesRef = firebase
        .firestore()
        .collection("usernames")
        .doc(currentUser.uid);
      try {
        if (username) {
          await usernamesRef.update({ name: newUsername });
        } else {
          await usernamesRef.set({ name: newUsername });
        }
        setUsername(newUsername);
        setEditMode(false);
      } catch (error) {
        console.error("Error updating username:", error);
      }
    }
  };
  

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleChange = (e) => {
    setNewUsername(e.target.value);
  };

  return (
    <div className="bg-white py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h3 className="text-2xl font-bold mb-4">User Page</h3>
        {/* <p className="mt-2 text-lg leading-8 text-gray-600 mb-4">
          This is a protected route for regular users.
        </p> */}
        {username ? (
          <>
            {editMode ? (
              <div className="flex items-left mb-4">
                <input
                  type="text"
                  value={newUsername}
                  onChange={handleChange}
                  className="text-xs mr-4 px-3 py-1.5 border border-gray-300 rounded-full focus:outline-none focus:ring focus:border-blue-500"
                />
                <button
                  onClick={handleSave}
                  className="text-xs rounded-full bg-blue-500 hover:bg-blue-700 px-3 py-1.5 mr-2 font-medium text-white"
                >
                  Save
                </button>
                <button
                  onClick={handleCancel}
                  className="text-xs rounded-full bg-red-500 hover:bg-red-700 px-3 py-1.5 font-medium text-white"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-3">
                  Username: {username}
                </h2>
                <button
                  onClick={handleEdit}
                  className="mt-3 text-xs rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                >
                  Edit Username
                </button>
              </>
            )}
          </>
        ) : (
          <>
            <p className="text-lg leading-8 text-gray-600 mb-4">
              You don't have a username yet. Please create one below:
            </p>
            <input
              type="text"
              value={newUsername}
              onChange={handleChange}
              className="text-xs mr-4 px-3 py-1.5 border border-gray-300 rounded-full focus:outline-none focus:ring focus:border-blue-500"
            />
            <button
              onClick={handleSave}
              className="text-xs rounded-full bg-blue-500 hover:bg-blue-700 px-3 py-1.5 mr-2 font-medium text-white"
            >
              Save Username
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default UserPage;
