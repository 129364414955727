import HomeImg from "./images/home-img.png";

import { Link } from "react-router-dom";

export default function App() {
  return (
    <div className="h-screen bg-yellow-600 text-gray-900 py-24 sm:py-32">
      <section className="home grid mx-auto max-w-7xl px-6 lg:px-8">
        <div className="home__container container grid content-center gap-12 lg:max-w-5xl lg:grid-cols-2 lg:items-center">
          <div className="home__data ">
            <p className="pb-2 font-semibold">Error 404</p>
            <h1 className="pb-4 text-5xl font-bold lg:text-6xl">Hey Buddy</h1>
            <p className="pb-8 font-semibold">
              We can't seem to find the page <br />
              you are looking for.
            </p>
            <Link
              to="/"
              className="inline-flex items-center justify-center rounded-full bg-gray-900 py-4 px-8 font-bold text-white"
            >
              Go Home
            </Link>
          </div>

          <div className="home__img justify-self-center">
            <img
              src={HomeImg}
              className="w-64 animate-floting lg:w-[400px]"
              alt="404 not founds"
            />
            <div className="home__shadow mx-auto h-5 w-36 animate-shadow rounded-[50%] bg-gray-900/30 blur-md lg:w-64"></div>
          </div>
          <div className="home__footer flex items-center justify-center gap-2 self-end text-sm font-semibold">
            <p>+1-(855) 875-2235</p>
            <p>|</p>
            <p>info@labelslab.com</p>
          </div>
        </div>
      </section>
    </div>
  );
}
