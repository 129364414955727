// AdminPage.js
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const AdminPage = () => {
  const [usernames, setUsernames] = useState({}); // State to store usernames
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: "",
    description: "",
    image: "",
  });

  const fetchEvents = async () => {
    const eventsRef = firebase.firestore().collection("events");
    const snapshot = await eventsRef.get();
    const eventsData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEvents(eventsData);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };

  const handleAddEvent = () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const uid = user.uid;
      const eventsRef = firebase.firestore().collection("events");
      const newEventWithUid = {
        ...newEvent,
        postedBy: uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      }; // Add uid and timestamp to the new event object
      eventsRef.add(newEventWithUid).then(() => {
        setNewEvent({
          title: "",
          description: "",
          image: "",
        });
        fetchEvents();
      });
    }
  };

  const handleDeleteEvent = (eventId) => {
    const eventsRef = firebase.firestore().collection("events");
    eventsRef.doc(eventId).delete();
    fetchEvents();
  };

  useEffect(() => {
    const fetchUsernames = async () => {
      const usernamesRef = firebase.firestore().collection("usernames");
      const snapshot = await usernamesRef.get();
      const usernamesData = {};

      snapshot.forEach((doc) => {
        usernamesData[doc.id] = doc.data().name;
      });

      setUsernames(usernamesData);
    };

    fetchUsernames();
  }, []);

  const getUsername = (userId) => {
    const username = usernames[userId];
    return username || userId; // Use the username if available, otherwise fallback to UID
  };

  return (
    <div className="admin-page">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Welcome Admin{" "}
            </div>
          </div>
          <div>
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-4">
              Create new event Below
            </h1>
            <div className="mt-10 admin-page__create-event space-y-">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Title"
                  value={newEvent.title}
                  onChange={handleInputChange}
                  className="mt-1 mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 mt-5"
                >
                  Description
                </label>
                <textarea
                  name="description"
                  id="description"
                  placeholder="Description"
                  value={newEvent.description}
                  onChange={handleInputChange}
                  className="mt-1 mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                ></textarea>
              </div>
              <div>
                <label
                  htmlFor="image"
                  className="block text-sm font-medium text-gray-700 mt-5"
                >
                  Image URL
                </label>
                <input
                  type="text"
                  name="image"
                  id="image"
                  placeholder="Image URL"
                  value={newEvent.image}
                  onChange={handleInputChange}
                  className="mt-1 mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <button
                  type="submit"
                  onClick={handleAddEvent}
                  className="mt-5 mb-2 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 "
                >
                  Add Event
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        ></div>
      </div>

      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-7">
              Previous Events
            </h2>
            <div className="relative py-1 text-sm text-gray-600">
              Please note that you can only delete the announcements that you
              have created. If you want to delete announcements created by other
              users, please contact the respective user directly and ask them to
              delete their own announcements.
            </div>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-1"></div>
          <div className="admin-page__events">
            {events.map((event) => {
              if (event.postedBy === firebase.auth().currentUser?.uid) {
                return (
                  <div key={event.id} className="admin-page__event">
                    <h3 className="text-lg font-bold tracking-tight text-gray-900">
                      {event.title}
                    </h3>
                    <p className="mt-2 text-lg leading-8 text-gray-600">
                      {event.description}
                    </p>

                    {event.uid && (
                      <p className="mt-2 text-sm leading-8 text-black">
                        Posted By: {getUsername(event.uid)}
                      </p>
                    )}

                    {event.timestamp && (
                      <div className="mt-2 text-sm leading-8 text-black">
                        <p>
                          Created at:{" "}
                          {event.timestamp &&
                            event.timestamp.toDate().toLocaleString()}
                        </p>
                      </div>
                    )}

                    {event.image && (
                      <img
                        src={event.image}
                        alt="Event"
                        className="admin-page__event-image"
                      />
                    )}
                    <button
                      onClick={() => handleDeleteEvent(event.id)}
                      className="mt-2 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                    >
                      Delete
                    </button>
                    <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-1"></div>
                  </div>
                );
              } else {
                return null; // Render nothing if the condition is not met
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
