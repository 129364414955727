import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./Homepage.css"; // Import the CSS file for Homepage styling

const Homepage = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [usernames, setUsernames] = useState({}); // State to store usernames

  const fetchEvents = async () => {
    const eventsRef = firebase.firestore().collection("events");
    const snapshot = await eventsRef.get();
    const eventsData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEvents(eventsData);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    const fetchUsernames = async () => {
      const usernamesRef = firebase.firestore().collection("usernames");
      const snapshot = await usernamesRef.get();
      const usernamesData = {};

      snapshot.forEach((doc) => {
        usernamesData[doc.id] = doc.data().name;
      });

      setUsernames(usernamesData);
    };

    fetchUsernames();
  }, []);

  const handleSeenEvent = async (eventId) => {
    const currentUser = firebase.auth().currentUser;
    const eventRef = firebase.firestore().collection("events").doc(eventId);

    try {
      await eventRef.update({
        isRead: firebase.firestore.FieldValue.arrayUnion(currentUser.uid),
      });
    } catch (error) {
      console.error("Error updating event:", error);
    }
    fetchEvents();
  };

  const openModal = (event) => {
    setSelectedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  const getUsername = (userId) => {
    const username = usernames[userId];
    return username || userId; // Use the username if available, otherwise fallback to UID
  };

  return (
    <div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Announcements
            </h2>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-1"></div>
          {events.map((event) => (
            <div key={event.id}>
              <div className="event-item ">
                <div className="py-5">
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span> {event.title}</span>

                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height="24"
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M6 9l6 6 6-6"></path>
                        </svg>
                      </span>
                    </summary>
                    <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                      {event.description}
                    </p>

                    {event.image && (
                      <img src={event.image} alt="Event" className="mt-7" />
                    )}
                  </details>
                </div>
              </div>
              <div className="flex flex-row items-center">
                {event.isRead &&
                event.isRead.includes(firebase.auth().currentUser.uid) ? (
                  <button
                    className="mt-5 h-10 w-20 rounded-full bg-sky-200 p-2"
                    disabled
                  >
                    👀✅
                  </button>
                ) : (
                  <button
                    className="mt-5 h-10 w-10 rounded-full bg-gray-50 p-2"
                    onClick={() => handleSeenEvent(event.id)}
                  >
                    👀
                  </button>
                )}

                {event.isRead && (
                  <p className="mt-5 text-sm leading-6 text-gray-600 ml-6">
                    Seen by: {event.isRead.length}{" "}
                    <button
                      onClick={() => openModal(event)}
                      className="text-blue-500 hover:underline focus:outline-none"
                    >
                      See names
                    </button>
                  </p>
                )}
                {event.postedBy && (
                  <p className="mt-5 text-sm leading-6 text-black ml-6">
                    Posted by:{getUsername(event.postedBy)}
                  </p>
                )}
              </div>

              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-1"></div>
            </div>
          ))}
        </div>
      </div>

      {selectedEvent && (
        <div className="modal">
          <div className="modal-content">
            <h3 className="text-xl font-bold">Seen by:</h3>
            <ul className="user-list">
              {selectedEvent.isRead.map((userId) => (
                <li key={userId}>{getUsername(userId)}</li>
              ))}
            </ul>

            <h3 className="text-xl font-bold">Remaining:</h3>
            <ul className="user-list">
              {Object.keys(usernames).map((userId) => {
                if (!selectedEvent.isRead.includes(userId)) {
                  return <li key={userId}>{getUsername(userId)}</li>;
                }
                return null;
              })}
            </ul>

            <button
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Homepage;
